<template>
  <div class="login">
    <div style="postion:relative;">
      <van-icon name="cross" class="closeicon" @click="toBack" />
    </div>
    <div>
      <img src="../assets/img/logo.jpg" class="login_logo" />
    </div>
    <div class="form" style="margin-top:0.9rem;">
      <van-cell-group class="login_form">
        <van-field
          v-model="phone"
          label="账号"
          placeholder="请输入手机号"
          :error-message="phoneErr"
          border
          required
          class="loginfrom_input"
          @blur="phoneValidata"
        />

        <van-field
          v-model="password"
          label="密码"
          placeholder="请输入密码"
          type="password"
          required
          :error-message="passwordErr"
          class="loginfrom_input"
          @blur="passwordValidata"
          border
        />
        <van-field
          v-model="surepassword"
          label="确认密码"
          placeholder="请输入密码"
          type="password"
          required
          :error-message="surepasswordErr"
          class="loginfrom_input"
          @blur="passwordSureValidata"
          border
        />
        <van-field
          v-model="verification"
          center
          clearable
          class="loginfrom_input"
          label="短信验证码"
          placeholder="请输入短信验证码"
          use-button-slot
          v-if="isyz"
        >
          <van-button slot="button" size="small" type="primary" @click="sendCode">发送验证码</van-button>
        </van-field>
        <van-checkbox v-model="radio" checked-color="#07c160" style="margin-bottom:0.3rem;">收不到验证码？</van-checkbox>
      </van-cell-group>
      <van-button type="info" class="loginbtn" @click="toRegister">注册</van-button>
    </div>
    <div class="login-modify">
      <p @click="toLogin">去登陆</p>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      show: false,
      value: "",
      is: false,
      password: "",
      pais: true,
      phone: "",
      phoneErr: "",
      passwordErr: "",
      verification: "",
      surepasswordErr: "",
      surepassword: "",
      key: "",
      radio: false,
      isyz: true
    };
  },

  computed: {},
  methods: {
    getauthorize(e) {
      // this.radio = !this.radio;
    },
    phoneValidata() {
      let phone = this.phone;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        this.phoneErr = "请输入正确手机号";
        return false;
      } else {
        this.phoneErr = "";
      }
    },
    toLogin() {
      this.$router.back();
    },
    passwordValidata() {
      let password = this.password;
      if (password.length < 6) {
        this.passwordErr = "密码长度不小于6位";
        return false;
      } else {
        this.passwordErr = "";
      }
    },
    passwordSureValidata() {
      if (this.surepassword != this.password) {
        this.surepasswordErr = "两次密码不一致";
        return false;
      } else {
        this.surepasswordErr = "";
      }
    },
    toBack() {
      this.$router.back();
    },
    sendCode() {
      this.$http
        .getCode({ phone: this.phone })
        .then(res => {
          console.log(res);
          this.key = res.key;
        })
        .catch(err => {
          Toast("账号已存在");
        });
    },
    toRegister() {
      if (!this.verification) {
        this.$http
          .getToRegister({
            phone: this.phone,
            password: this.password
          })
          .then(res => {
            Toast.success("注册成功");
          })
          .catch(err => {
            Toast("账号已存在");
            this.$router.back();
          });
      } else {
        this.$http
          .getToRegister({
            phone: this.phone,
            password: this.password,
            verification_key: this.key,
            verification_code: this.verification
          })
          .then(res => {
            Toast.success("注册成功");
          })
          .catch(err => {
            console.log(err);
            Toast.fail("注册失败");
          });
      }
    }
  },
  watch: {
    radio(o) {
      if (o) {
        this.isyz = false;
      } else {
        this.isyz = true;
      }
    }
  }
};
</script>

<style scoped>
.login_logo {
  height: 2.5rem;
  width: 2.5rem;
  display: block;
  margin: 0 auto;
  margin-top: 0.5rem;
}
.login_title {
  text-align: center;
  margin: 0.3rem 0;
  font-weight: bold;
  font-size: 0.5rem;
}
.login-form {
  margin-bottom: 0.3rem;
}
.form {
  padding: 0 0.5rem;
}
.van-cell-group {
  background: #fff;
}
.loginfrom_input {
  margin-bottom: 0.27rem;
  border: 1px solid #ddd;
}
.loginbtn {
  display: block;
  width: 70%;
  margin: 0 auto;
  height: 0.7rem;
  text-align: center;
  line-height: 0.7rem;
  font-size: 0.3rem;
  font-weight: bold;
}
.login-modify {
  margin-top: 0.3rem;
}
.login-modify p {
  float: right;
  margin-right: 0.5rem;
  font-size: 0.3rem;
}
.closeicon {
  right: 0.6rem;
  top: 0.1rem;
  position: absolute;
  padding: 0.3rem;
}
</style>
